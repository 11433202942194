<template>
  <div>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="accent"
          icon="mdi-file-code"
          inline
          class="px-5 py-3 mt-7"
          style="width: 100%"
        >
          <template v-slot:after-heading>
            <v-row>
              <v-col
                cols="12"
                md="6"
                lg="4"
              >
                <ViewAsCustomer />
              </v-col>
            </v-row>
          </template>
          <ContentLibraryMain
            :root="root"
            :file-upload-accepted-extensions="accepetedFileExtensions"
            no-rename-folder
            no-delete-folder
            no-create-folder
            no-upload-files
            no-delete-checked
            no-delete-selected
          />
        </base-material-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ContentLibraryDataFeed',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer'),
    ContentLibraryMain: () =>
      import('@/views/pages/components/content-library/ContentLibraryMain')
  },

  data () {
    return {
      root: process.env.VUE_APP_CL_DATA_FEED,
      accepetedFileExtensions: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
